import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { Http, ResponseContentType } from '@angular/http';
@Injectable({
    providedIn: 'root'
})
export class ConsentService {
    constructor(private _data: DataService, private http: Http) { }
    GetSubscriberDetails(consentHistoryId: number, applicationHistoryId: number): Observable<any> {
        var model =
        {
            consentHistoryId: consentHistoryId,
            applicationHistoryId: applicationHistoryId
        }
        return this._data.post(`Consent/GetSubscriberDetails`, model)
            .pipe(map(response => {
                return response;
            }));
    }
    ConsentSubmit(consentHistoryId): Observable<any> {
        return this._data.post(`Consent/ConsentSubmit`, consentHistoryId)
            .pipe(catchError(this.handleError)
            );
    }
    private handleError(error: Response | any) {
        console.error(error.message || error);
        return observableThrowError(error.status)
    }
    GetConsentHistory(applicationHistoryId: any): Observable<any> {
        return this._data.get(`Consent/GetConsentHistory`, applicationHistoryId)
            .pipe(map(response => {
                return response;
            }));
    }
    CreatePDF(hTMLContent: string, chartImage: string, consentHistoryId: number, applicationHistoryId: number): Observable<any> {
        return this._data.post('Consent/GetConsentPDF', { hTMLContent: hTMLContent, chartImage: chartImage, consentHistoryId: consentHistoryId, applicationHistoryId: applicationHistoryId })
            .pipe(map(response => {
                return response;
            }));
    }
    DeletePDF(FileName: string): Observable<any> {
        return this._data.post('Consent/DeletePDFFile/' + FileName, { fileName: FileName })
            .pipe(map(response => {
                return response;
            }));
    }
    public downloadPdf(url): Observable<any> {
        return this.http.get(url, { responseType: ResponseContentType.Blob }).pipe(map(
            (res) => {
                return new Blob([res.blob()], { type: 'application/pdf' });
            }));
    }
}